import React from "react"

const Step5 = () => {
  return (
    <div className="align-center">
      <div
        style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold" }}
      >
        A Valentine's Day Story
      </div>
      <div
        style={{
          fontSize: "18px",
          textIndent: "50px",
          textAlign: "justify",
          letterSpacing: "1px",
          marginRight: "20px",
        }}
      >
        <p>
          Mystic Falls High wasn't exactly known for its normal after-school
          activities. For Palak Patel, it meant sneaking out for a moonlit hunt,
          the metallic tang of blood an intoxicating secret on her tongue.
          Tonight, however, her senses picked up something new. Laughter, rich
          and warm, drifted from the football field. Drawn by an unknown
          impulse, she found herself perched on the bleachers, watching a game
          under the spectral glow of the floodlights. There, weaving through the
          pack of players with impossible grace, was Shikhar Dixit. His dark
          hair fell across his forehead, his laughter echoing in the crisp air,
          and her heart did a strange double-beat.{" "}
        </p>
        <p>
          Shikhar, for his part, felt a prickle at the back of his neck all
          game. A phantom chill, a scent like rain-soaked earth, but there was
          nobody there. Yet, his eyes kept straying to the shadows, searching
          for the unseen observer. Later, during his post-game shower, the image
          of a girl with eyes like polished obsidian flashed through his mind.
          He shrugged it off, attributing it to the pre-Valentine's Day
          adrenaline, but a smile lingered on his lips. Back in her room, bathed
          in the pale moonlight, Palak replayed the scene. The thrill of the
          hunt was forgotten, replaced by a yearning she couldn't understand.
        </p>
        <p>
          "He smells like sunshine and cinnamon," she confessed to her closest
          confidante, a mischievous ghost named Nithya. Nithya's spectral form
          shimmered with amusement. "Ah, the forbidden fruit," she teased.
          "Human or werewolf, love has a strange way of finding the most
          inconvenient hearts." Palak rolled her eyes, but a secret blush warmed
          her cheeks. The forbidden, maybe, but Shikhar Dixit was definitely
          intriguing.
        </p>
        <p>
          Across town, Shikhar confided in his best friend, a wisecracking
          werewolf named James. "There was this girl," he started, then blushed
          furiously. James raised an eyebrow. "Not telling me it's that
          cheerleader again, are you?" Shikhar shook his head, the image of the
          dark-haired girl returning even sharper. "Different," he mumbled.
          "Like a mystery wrapped in shadows." James chuckled. "Sounds
          dangerous, Dixit. Maybe you should stick to the cheerleaders." But
          even as he said it, a glint of curiosity flickered in his eyes. Mystic
          Falls High was about to witness a love story unlike any other, a dance
          between moonlight and sunlight, a waltz between fangs and claws, all
          set to the unknown rhythm of their beating hearts.
        </p>
      </div>
    </div>
  )
}

export default Step5
