import React, { useEffect, useState } from "react"

import Entry from "../pages/entry"
import { navigate } from "gatsby"
import Step1 from "../components/valentinesDaySteps/Step1"
import Step2 from "../components/valentinesDaySteps/Step2"
import Step3 from "../components/valentinesDaySteps/Step3"
import Step4 from "../components/valentinesDaySteps/Step4"
import Step5 from "../components/valentinesDaySteps/Step5"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { Button } from "@material-ui/core"

const ValentineAsk = ({ setAccepted }) => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        fontSize: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "pink",
        }}
      >
        Palu, will you be my Valentine? ❤️
        <img
          src="https://media.tenor.com/pCzzLl4KbY0AAAAM/fingers-crossed-luck.gif"
          alt="gif"
          style={{
            width: "500px",
            height: "300px",
            borderRadius: "10px",
          }}
        ></img>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "170px",
            marginLeft: "100px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setAccepted(true)
            }}
            style={{
              backgroundColor: "green",
              color: "white",
              fontSize: "16px",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              alert("Hehhhhhh!!? SU CHE!?")
            }}
            style={{
              backgroundColor: "red",
              color: "white",
              fontSize: "16px",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            No
          </Button>
        </div>
      </div>
    </div>
  )
}

const ValentineDayContent = () => {
  const [step, setStep] = useState(1)
  const [showCelebration, setShowCelebration] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setShowCelebration(false)
    }, 4000)
  }, [])

  if (showCelebration) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: "100px", color: "greenyellow" }}>
          SHE SAID YESSS WAHOOOO
        </div>
        <div style={{ fontSize: "100px" }}>🎉🥳💘🌹💕</div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "pink",
      }}
    >
      {step > 1 && (
        <Button
          onClick={() => {
            setStep(step - 1)
          }}
        >
          <ArrowBackIosIcon
            style={{
              fontSize: "50px",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
        </Button>
      )}
      <div>
        <div
          style={{
            fontFamily: "Dancing Script", // Handwritten style
            fontSize: "30px",
            color: "red", // Gradient effect
            textAlign: "center",
            padding: "5px",
            fontWeight: "bold",
            backgroundColor: "pink",
          }}
        >
          Happy Valentine's Day!
        </div>
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {step === 4 && <Step4 />}
        {step === 5 && <Step5 />}
      </div>
      {step < 5 && (
        <Button
          onClick={() => {
            setStep(step + 1)
          }}
        >
          <ArrowForwardIosIcon
            style={{
              fontSize: "50px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
          />
        </Button>
      )}
    </div>
  )
}

const ValentinesDay = ({ location }) => {
  const [accepted, setAccepted] = useState(false)
  return (
    <div className="valentinesDayBackground">
      <Entry location={location}>
        <div className="container bg-red">
          <div style={{ marginTop: -20, marginBottom: 10 }}>
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: 100, height: 30, fontSize: 14 }}
              onClick={() => {
                navigate("/")
              }}
            >
              Go Back
            </button>
          </div>
          {accepted ? (
            <ValentineDayContent />
          ) : (
            <ValentineAsk setAccepted={setAccepted} />
          )}
        </div>
      </Entry>
    </div>
  )
}

export default ValentinesDay
