import React from "react"

const Step1 = () => {
  return (
    <div className="align-center">
      <div
        style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold" }}
      >
        Literally us in a nutshell. *This* is what I dream about before I sleep.
      </div>
      <img
        style={{
          width: "auto",
          height: "800px",
          borderRadius: "10px",
        }}
        src={require("../../assets/valentines/Card1.jpg").default}
        title={"valentinesCard"}
        alt={"valentinesCardAlt"}
      />
    </div>
  )
}

export default Step1
