import React from "react"

const Step4 = () => {
  return (
    <div className="align-center">
      <div
        style={{ fontSize: "18px", textAlign: "center", fontWeight: "bold" }}
      >
        A Valentine's Day Poem
      </div>
      <div style={{ fontSize: "18px", textAlign: "center" }}>
        <p>I've never felt like this </p>
        <p>The way she sat, sang, I (satsangi) </p>
        <p>Have never felt like this </p>
        <p>Like I can watch her for eternity </p>
        <p>That I've never felt like this </p>
        <p>A palet as refined as the chefs she scrutinizes </p>
        <p>But rises like a vampire in the night it moves </p>
        <p>Her heart as caring as the speed </p>
        <p>Her eyes so beautiful one stare I lose </p>
        <p>My whole thought process </p>
        <p>Not one for touch yet she accepts me whole </p>
        <p>Even when I get her crochet kits that beat her soul </p>
        <p>Even when I code till the night is whole (dw I'll control hehe)</p>
        <p>Even when my selfishness takes its toll (I'll be better) </p>
        <p>Her patience and virtue seize control</p>
        <p>And you know... it's deeper than the fact I love you</p>
        <p>That's already known</p>
        <p>It's that we are in this journey of life together</p>
        <p>And our karmas sown</p>
        <p>It's deeper than the fact you're my soul mate</p>
        <p>It's the fact that no matter the time flies</p>
        <p> I can feel so deeply</p>
        <p>that...</p>{" "}
        <p syle={{ color: "black", fontWeight: "bold" }}>
          I've never felt this way before ❤️
        </p>
      </div>
    </div>
  )
}

export default Step4
